import React from "react"

import BlockContent from "../../blockContent/blockContent"

import slugify from "../../../helpers/slugify"

import styles from "./features.module.scss"

const Features = ({ featureList }) => {
  return featureList.map(cat => (
    <div key={cat.title} className={styles.category}>
      <h2 id={slugify(cat.title)}>{cat.title}</h2>
      <div className="grid fullgap">
        {cat.feature.map(feature => (
          <div key={feature.title} className="gridItem small-12 medium-4" id="todo">
            <h3>{feature.title}</h3>
            <BlockContent blocks={feature.text} />
            {/* anchor instead of link:
             https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#recommendations-for-programmatic-in-app-navigation  */}
            {feature.url ? (
              <a href={feature.url} className="link-pink">
                Find out more
              </a>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  ))
}

export default Features
