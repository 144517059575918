import React from "react"

import slugify from "../../../helpers/slugify"

const FeaturesCategories = ({ featureList }) => (
  <ul>
    {featureList.map(cat => (
      <li key={cat.title}>
        <a href={`/elearning-authoring-tool/feature-list#${slugify(cat.title)}`} className="link-pink">
          {cat.title}
        </a>
      </li>
    ))}
  </ul>
)

export default FeaturesCategories
