import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import * as Layout2Col from "../../components/layout/layout2col/layout2col"
import Seo from "../../components/seo/seo"
import HeroSimple from "../../components/hero/heroSimple/heroSimple"

import Features from "../../components/featuresList/features/features"
import FeaturesCategories from "../../components/featuresList/featuresCategories/featuresCategories"

const FeatureList = ({ data }) => {
  const { content, seo } = data.sanityFeatureListPage.tabs

  return (
    <Layout>
      <Seo title="Feature List" isBlogPost={false} {...seo} />
      <HeroSimple blocks={content.hero[0].children} subtitle={content.subtitle} />
      <Layout2Col.Wrapper>
        <Layout2Col.Main>
          <Features featureList={content.featureList} />
        </Layout2Col.Main>
        <Layout2Col.Sidebar>
          <FeaturesCategories featureList={content.featureList} />
        </Layout2Col.Sidebar>
      </Layout2Col.Wrapper>
    </Layout>
  )
}

export default FeatureList

export const query = graphql`
  query {
    sanityFeatureListPage {
      tabs {
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
        content {
          subtitle
          hero: _rawHero
          featureList {
            title
            feature {
              url
              title
              text: _rawText(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    }
  }
`
